import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import BookCard from "../components/cards/bookCard"
import ProductCard from "../components/cards/productCard"

const RenderBody = ({ coloringBooks, books, meta, socialCard }) => (
  <>
    <Helmet
      title={"Store"}
      titleTemplate={`%s | ${meta.title}`}
      meta={[
        {
          name: `description`,
          content: meta.description,
        },
        {
          property: `og:title`,
          content: meta.title,
        },
        {
          property: `og:description`,
          content: meta.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: meta.author,
        },
        {
          name: `twitter:title`,
          content: meta.title,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:image",
          content: `${socialCard.publicURL}`,
        },
        {
          property: "og:image",
          content: `${socialCard.publicURL}`,
        },
      ].concat(meta)}
    />
    <div className="w-full flex justify-center items-center flex-row content-center py-24">
      <div className="w-full desktop:w-4/5 4k:w-2/5 flex justify-center items-center flex-wrap flex-row px-8 desktop:px-0">
        <div className="w-full pb-12">
          <div className="w-full">
            <h2>Coloring Books</h2>
          </div>
          <div className="w-full grid grid-cols-1 tablet:grid-cols-3 laptop:grid-cols-5 desktop:grid-cols-5 4k:grid-cols-5 gap-12">
            {coloringBooks.map((product, i) => (
              <ProductCard
                key={i}
                title={product.node.title}
                slug={product.node.slug}
                id={product.node.id}
                image={product.node.image.gatsbyImageData}
                price={product.node.price}
                link={product.node.link}
              />
            ))}
          </div>
        </div>
        <div className="w-full pt-12">
          <div className="w-full">
            <h2>Books</h2>
          </div>
          <div className="w-full grid grid-cols-1 tablet:grid-cols-3 laptop:grid-cols-5 desktop:grid-cols-5 4k:grid-cols-5 gap-12">
            {books.map((book, i) => (
              <BookCard
                key={i}
                title={book.node.title}
                slug={book.node.slug}
                id={book.node.id}
                image={book.node.cover.gatsbyImageData}
                price={book.node.price}
                link={book.node.link}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </>
)

const StoreHome = ({ data }) => {
  //Required check for no data being returned
  const meta = data.site.siteMetadata
  const coloringBooks = data.allGraphCmsColoringBook.edges
  const books = data.allGraphCmsBook.edges
  const socialCard = data.file

  if (!coloringBooks) return <div>Error returning coloring book data.</div>
  if (!books) return <div>Error returning book data.</div>

  return (
    <Layout>
      <RenderBody coloringBooks={coloringBooks} books={books} meta={meta} socialCard={socialCard} />
    </Layout>
  )
}

export default StoreHome

RenderBody.propTypes = {
  coloringBooks: PropTypes.array.isRequired,
  books: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  query ProductList {
    allGraphCmsColoringBook(sort: { fields: coloringBookId, order: ASC }) {
      edges {
        node {
          id
          title
          slug
          link
          coloringBookId
          category {
            id
            slug
            title
          }
          image {
            gatsbyImageData
          }
        }
      }
    }
    allGraphCmsBook {
      edges {
        node {
          title
          slug
          price
          description
          id
          link
          cover {
            gatsbyImageData
          }
          categories {
            title
            slug
            id
          }
        }
      }
    }
    file(name: {eq: "SocialCardTemplate-Store"}) {
      id
      name
      publicURL
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
