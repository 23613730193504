import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import tw from 'twin.macro';

const BookCover = styled("div")`
    ${tw`w-full h-full rounded-md`}
`

const BookCard = ({ link, image, title, id }) => (
    <a href={`${link}`} target="_blank" rel="noopener" rel="noreferrer">
        <div className="flex flex-row flex-wrap h-auto rounded-lg" key={id} >
            <BookCover>
                <GatsbyImage image={image} className="phone:w-full w-auto phone:h-full h-96 shadow-lg object-scale-cover" alt={title} />
            </BookCover>
        </div>
    </a>
)

export default BookCard;

BookCard.propTypes = {
    link: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}